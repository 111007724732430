<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">兑换审核</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">待审核</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>用户ID</span>
              <el-input placeholder="请输入用户id" v-model="page.query.userId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="9">
            <div>
              <span>商品名称</span>
              <el-input placeholder="请输入商品名称" v-model="page.query.goodsName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="ID" width="200" v-if="false">
        </el-table-column>
        <el-table-column prop="userId" align="center" label="用户ID" width="200">
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" width="200">
        </el-table-column>
        <el-table-column prop="goodsNum" align="center" label="兑换数量" width="200">
        </el-table-column>
        <el-table-column prop="depletePoints" align="center" label="消费积分" width="200">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="180">
          <template slot-scope="scope">
            <span>{{ showStatus(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="检测" placement="top">
              <el-button @click="onExamine(scope.row)" circle icon="el-icon-view" type="danger"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row)" circle icon="el-icon-edit" type="primary"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="修改审核状态" width="30%" center :visible.sync="dialogStatusVisible">
      <div>
        <el-form :model="exchange">
          <div>
            <el-form-item label="用户ID" label-width="100px" prop="userId">
              <el-input v-model="exchange.userId" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="兑换商品" label-width="100px" prop="goodsName">
              <el-input v-model="exchange.goodsName" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="兑换数量" label-width="100px" prop="goodsNum">
              <el-input v-model="exchange.goodsNum" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="消耗积分" label-width="100px" prop="depletePoints">
              <el-input v-model="exchange.depletePoints" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="状态" label-width="100px" prop="status">
              <!--只能修改为以下山中状态 -->
              <el-select v-model="exchange.status" placeholder="请选择状态">
                <el-option label="审核中" value="1"></el-option>
                <el-option label="审核通过" value="2"></el-option>
                <el-option label="审核未通过" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="失败原因" label-width="100px" prop="failReason" v-if="exchange.status == '3'">
              <el-input v-model="exchange.failReason"></el-input>
            </el-form-item>
          </div>
          <div class="update-but">
            <el-button size="mini" @click="dialogStatusVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="onUpdated">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="数据检测" width="30%" center :visible.sync="dialogDateVisible">

      <el-form :model="examine">
        <div>
          <el-form-item label="账号金币检测" label-width="100px" prop="userId">
            <i :class="handleClass(examine.goldFlag)" :style="examine.goldFlag ? 'color:green;' : 'color:red;'"> {{
              examine.goldFlag ? '通过' : '不通过' }}</i>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="账号积分检测" label-width="100px" prop="userId">
            <i :class="handleClass(examine.pointFlag)" :style="examine.pointFlag ? 'color:green;' : 'color:red;'">{{
              examine.pointFlag ? '通过' : '不通过' }}</i>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          userId: '',
          goodsName: '',
          status: '1'
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      exchange: {},
      dialogStatusVisible: false,
      dialogDateVisible: false,
      examine: {}
    }
  },
  created() {
    this.getLsit()
  },
  methods: {
    handleClass(val) {
      if (val) {
        return 'el-icon-circle-check'
      }
      if (!val) {
        return 'el-icon-circle-close'
      }
    },
    onExamine(row) {
      this.dialogDateVisible = true
      this.getExamine(row)
    },

    showStatus(val) {
      const types = window.sessionStorage.getItem('exchange_status_type')
      const s = JSON.parse(types).find(x => x.dictValue === val)
      return s.dictLabel
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/exchange/getPageByExchangeList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.userId = ''
      this.page.query.goodsName = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    },
    onDetect(row) {
      this.dialogDetectVisible = true
    },
    onClick(row) {
      this.exchange = row
      this.dialogStatusVisible = true
    },
    async onUpdated() {
      if (this.exchange.status == '3' && (this.exchange.failReason == '' || this.exchange.failReason == null)) {
        return this.$message.error('请录入审核失败原因！')
      }
      if (this.exchange.status == '2') {
        this.exchange.isUse = '0'
      }
      const { data: res } = await this.$http.post('/admin/exchange/updateExchange', this.exchange)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.dialogStatusVisible = false
      this.getLsit()
      this.$message.success(res.msg)
    },
    async getExamine(row) {
      const { data: res } = await this.$http.post('/admin/exchange/examine', row)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.examine = res.data
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
