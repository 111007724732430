<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">应用管理</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">游戏配置</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>游戏ID</span>
              <el-input placeholder="请输入游戏ID" v-model="page.query.gameId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>游戏模式</span>
              <el-input placeholder="请输入游戏模式" v-model="page.query.gameMode">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="primary" size="mini" @click="onClick({}, 1)">新增</el-button>
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="主键ID" width="100">
        </el-table-column>
        <el-table-column prop="gameId" align="center" label="游戏ID" width="100">
        </el-table-column>
        <el-table-column prop="gameMode" align="center" label="游戏模式" width="100">
        </el-table-column>
        <el-table-column prop="image" align="center" label="游戏图片" width="150">
          <template slot-scope="scope">
            <img :src="scope.row.image" alt="" style="width: 100px; height: 100px; border-radius:5px">
          </template>
        </el-table-column>
        <el-table-column prop="gameTickets" align="center" label="门票" width="100">
        </el-table-column>
        <el-table-column prop="gameDesc" align="center" label="游戏规则" width="300">
          <template slot-scope="scope">
            <span>{{ handleDesc(scope.row.gameDesc) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="gameConfig" align="center" label="奖励配置" width="150">
        </el-table-column>
        <el-table-column prop="gamePagePath" align="center" label="游戏页面路径" width="300">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row, 3)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row, 2)" circle icon="el-icon-edit" type="primary"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click="onDel(scope.row)" circle icon="el-icon-delete" type="danger"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :title="handleTitle()" width="40%" center :visible.sync="dialoConfigVisible">
      <div>
        <el-form :model="gameConfig" :rules="gameConfigRules" ref="gameConfigRef">
          <div>
            <el-form-item label="id" label-width="100px" prop="id" v-if="false">
              <el-input v-model="gameConfig.id" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏名称" label-width="100px" prop="gameId">
              <el-select v-model="gameConfig.gameId" placeholder="请选择" :disabled="tancFlag != '1'">
                <el-option v-for="item in games" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏模式" label-width="100px" prop="gameMode">
              <el-input v-model="gameConfig.gameMode" placeholder="请输入游戏模式" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="游戏图片" label-width="100px">
              <el-upload class="upload-demo" :action="this.$http.defaults.baseURL + '/admin/common/update/image'"
                :multiple="false" :limit="1" :before-upload="beforeUpload" :on-success="handleAvatarSuccess"
                :headers="headers">
                <el-image :src="gameConfig.image" style="width: 100px; height: 100px; border-radius: 5px;"
                  v-if="gameConfig.image"></el-image>
                <el-image :src="require('../../../public/上传图片.png')"
                  style="width: 100px; height: 100px; border-radius: 5px;" v-else></el-image>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏图片" label-width="100px" prop="image">
              <el-input v-model="gameConfig.image" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="入场门票" label-width="100px" prop="gameTickets">
              <el-input v-model="gameConfig.gameTickets" placeholder="请输入入场所需的金币"
                @input="(v) => (gameConfig.gameTickets = v.replace(/[^\d.]/g, ''))"
                :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏规则" label-width="100px" prop="gameDesc">
              <el-input v-model="gameConfig.gameDesc" placeholder="请输入游戏规则" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="奖励配置" label-width="100px" prop="gameConfig">
              <el-input v-model="gameConfig.gameConfig" placeholder="请按照规定格式输入奖励配置"
                :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏页面路径" label-width="100px" prop="gamePagePath">
              <el-input v-model="gameConfig.gamePagePath" placeholder="请输入游戏页面路径" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div style=" display: flex; justify-content: flex-end;" v-show="tancFlag != '3'">
            <el-button size="mini" @click="dialoConfigVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submit">确 定</el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          gameId: '',
          gameMode: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      gameConfig: {},
      dialoConfigVisible: false,
      gameConfigRules: {},
      tancFlag: '1',
      games: []
    }
  },
  created() {
    this.getLsit()
    this.getGameList()
  },
  methods: {
    async onDel(row) {
      row.isDel = '1'
      const { data: res } = await this.$http.post('/admin/gameConfig/updateGameConfig', row)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getLsit()
    },

    submit() {
      this.$refs.gameConfigRef.validate(async valid => {
        if (!valid) return
        if (this.tancFlag == '1') {
          const { data: res } = await this.$http.post('/admin/gameConfig/saveGameConfig', this.gameConfig)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        if (this.tancFlag == '2') {
          const { data: res } = await this.$http.post('/admin/gameConfig/updateGameConfig', this.gameConfig)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        this.dialoConfigVisible = false
        this.getLsit()
      })
    },
    async getGameList() {
      const { data: res } = await this.$http.get('/admin/game/getGameList')
      if (res.code !== 200) return this.$message.error(res.msg)
      this.games = res.data.map(item => {
        return { value: item.id, label: item.gameName }
      })
    },
    onClick(row, flag) {
      this.tancFlag = flag
      this.gameConfig = row
      this.dialoConfigVisible = true
    },
    handleTitle() {
      if (this.tancFlag == '1') {
        return '新增游戏配置'
      }
      if (this.tancFlag == '2') {
        return '修改游戏配置'
      }
      if (this.tancFlag == '3') {
        return '查看游戏配置详情'
      }
    },
    handleDesc(val) {
      if (val.length > 50) {
        return val.substring(0, 49) + '...'
      }
      return val
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/gameConfig/getPageByGameConfigList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.gameId = ''
      this.page.query.gameMode = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    },
    handleAvatarSuccess(e) {
      if (e.code !== 200) return this.$message.error('图片上传失败！')
      this.gameConfig.image = e.data
      this.$forceUpdate()
      // console.log('文件地址：' + e.data)
    },
    // 限制文件类型
    beforeUpload(file) {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$message.error('请上传png,jpeg格式的文件!')
        return false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
