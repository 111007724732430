<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">应用管理</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">游戏管理</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>游戏ID</span>
              <el-input placeholder="请输入游戏ID" v-model="page.query.id">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>游戏名称</span>
              <el-input placeholder="请输入游戏名称" v-model="page.query.gameName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>是否使用</span>
              <el-select v-model="page.query.isDel" placeholder="请选择状态">
                <el-option label="是" value="0"></el-option>
                <el-option label="否" value="1"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="primary" size="mini" @click="onClick({}, 1)">新增</el-button>
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="ID" width="100">
        </el-table-column>
        <el-table-column prop="gameName" align="center" label="游戏名称" width="200">
        </el-table-column>
        <el-table-column prop="gameDesc" align="center" label="游戏描述" width="400">
        </el-table-column>
        <el-table-column prop="gameImage" align="center" label="游戏封面" width="180">
          <template slot-scope="scope">
            <img :src="scope.row.gameImage" alt="" style="width: 100px; height: 100px; border-radius:5px">
          </template>
        </el-table-column>
        <el-table-column prop="isDel" align="center" label="数据状态" width="150">
          <template slot-scope="scope">
            <span>{{ showIsDel(scope.row.isDel) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row, 3)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row, 2)" circle icon="el-icon-edit" type="primary"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :title="handleTitle()" width="30%" center :visible.sync="dialoGameVisible">
      <div>
        <el-form :model="game" :rules="gameRules" ref="gameRef">
          <div>
            <el-form-item label="游戏id" label-width="100px" prop="id" v-if="tancFlag == '3'">
              <el-input v-model="game.id" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏名称" label-width="100px" prop="gameName">
              <el-input v-model="game.gameName" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="游戏描述" label-width="100px" prop="gameDesc">
              <el-input v-model="game.gameDesc" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="游戏封面" label-width="100px">
              <el-upload class="upload-demo" :action="this.$http.defaults.baseURL + '/common/update/image'"
                :multiple="false" :limit="1" :before-upload="beforeUpload" :on-success="handleAvatarSuccess"
                :headers="headers">
                <el-image :src="game.gameImage" style="width: 200px; height: 100px; border-radius: 5px;"
                  v-if="game.gameImage"></el-image>
                <el-image :src="require('../../../public/上传图片.png')"
                  style="width: 100px; height: 100px; border-radius: 5px;" v-else></el-image>
              </el-upload>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="封面URL" label-width="100px" prop="gameImage">
              <el-input v-model="game.gameImage" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="数据状态" label-width="100px" prop="isDel">
              <el-select v-model="game.isDel" placeholder="请选择" :disabled="tancFlag == '3'">
                <el-option v-for="item in isTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style=" display: flex; justify-content: flex-end;" v-show="tancFlag != '3'">
            <el-button size="mini" @click="dialoGameVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submit">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          id: '',
          gameName: '',
          isDel: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      isTypes: [],
      tancFlag: '1',
      dialoGameVisible: false,
      game: {},
      gameRules: {
        gameName: [{ required: true, message: '请输入游戏名称', trigger: ['blur'] }],
        gameDesc: [{ required: true, message: '请输入游戏描述', trigger: ['blur'] }],
        gameImage: [{ required: true, message: '请上传游戏封面', trigger: ['blur'] }],
        isDel: [{ required: true, message: '请选择数据状态', trigger: ['blur'] }]
      },
      headers: {
        'Access-Control-Allow-Origin': 'POST'
      }
    }
  },
  created() {
    this.getLsit()
    this.getDictByIsType()
  },
  methods: {
    submit() {
      this.$refs.gameRef.validate(async valid => {
        if (!valid) return
        if (this.tancFlag == '1') {
          const { data: res } = await this.$http.post('/admin/game/saveGame', this.game)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        if (this.tancFlag == '2') {
          const { data: res } = await this.$http.post('/admin/game/updateGame', this.game)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        this.dialoGameVisible = false
        this.getLsit()
      })
    },
    onClick(row, flag) {
      this.tancFlag = flag
      this.game = row
      this.dialoGameVisible = true
    },
    handleTitle() {
      if (this.tancFlag == '1') {
        return '新增游戏'
      }
      if (this.tancFlag == '2') {
        return '修改游戏'
      }
      if (this.tancFlag == '3') {
        return '查看游戏详情'
      }
    },

    // 查询单位字典项
    async getDictByIsType() {
      const types = window.sessionStorage.getItem('is_type')
      this.isTypes = JSON.parse(types).map(item => {
        return { value: item.dictValue, label: item.dictLabel }
      })
    },

    showIsDel(val) {
      const types = window.sessionStorage.getItem('is_type')
      const s = JSON.parse(types).find(x => x.dictValue === val)
      return s.dictLabel
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/game/getPageByGameList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.id = ''
      this.page.query.gameName = ''
      this.page.query.isDel = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    },
    handleAvatarSuccess(e) {
      if (e.code !== 200) return this.$message.error('图片上传失败！')
      this.game.gameImage = e.data
      this.$forceUpdate()
      // console.log('文件地址：' + e.data)
    },
    // 限制文件类型
    beforeUpload(file) {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$message.error('请上传png,jpeg格式的文件!')
        return false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
