<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">用户管理</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">系统用户</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow="hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>账号</span>
              <el-input placeholder="请输入账号" v-model="page.query.userName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>姓名</span>
              <el-input placeholder="请输入姓名" v-model="page.query.nickName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>电话</span>
              <el-input placeholder="请输入电话" v-model="page.query.phone" type="text" maxlength="11"
                @input="(v) => (page.query.phone = v.replace(/[^\d]/g, ''))">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="primary" size="mini" @click="dialogAdduserVisible = true">新增</el-button>
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="ID" width="200" v-if="false">
        </el-table-column>
        <el-table-column prop="userName" align="center" label="账号" width="200">
        </el-table-column>
        <el-table-column prop="nickName" align="center" label="姓名" width="200">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="电话" width="200">
        </el-table-column>
        <el-table-column prop="adder" align="center" label="地址" width="200">
        </el-table-column>
        <el-table-column prop="roles" align="center" label="职位" width="180">
          <template slot-scope="scope">
            <span>{{ showRoles(scope.row.roles) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row, true)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row, false)" circle icon="el-icon-edit" type="primary"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click="onDel(scope.row)" circle icon="el-icon-delete" type="danger"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <!--弹窗内容-->
    <el-dialog title="新增用户" width="50%" center :visible.sync="dialogAdduserVisible">
      <div>
        <el-form :model="addform" :rules="rules" ref="addform">
          <div>
            <el-form-item prop="nickName" label="姓名" label-width="100px">
              <el-input v-model="addform.nickName"></el-input>
            </el-form-item>
            <el-form-item prop="phone" label="电话" label-width="100px">
              <el-input v-model="addform.phone" type="text" maxlength="11"
                @input="(v) => (addform.phone = v.replace(/[^\d]/g, ''))"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="email" label="邮箱" label-width="100px">
              <el-input v-model="addform.email"></el-input>
            </el-form-item>
            <el-form-item prop="adder" label="地址" label-width="100px">
              <el-input v-model="addform.adder"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="roles" label="职位" label-width="100px">
              <el-select v-model="addform.roles" multiple filterable allow-create default-first-option
                placeholder="请选择职位">
                <el-option v-for="item in sysRoles" :key="item.id" :label="item.roleName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="float: right;">
            <el-button size="mini" @click="dialogAdduserVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="addUser()">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :title="disabled ? '用户信息查看' : '用户信息编辑'" width="50%" center :visible.sync="dialogUserVisible">
      <div>
        <el-form :model="userInfo" :rules="updateRules" ref="updateRules">
          <div>
            <el-form-item label="id" label-width="100px" prop="id" v-if="false">
              <el-input v-model="userInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="账号" label-width="100px" prop="userName">
              <el-input v-model="userInfo.userName" disabled></el-input>
            </el-form-item>
            <el-form-item label="姓名" label-width="100px" prop="nickName">
              <el-input v-model="userInfo.nickName" :disabled="disabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="电话" label-width="100px" prop="phone">
              <el-input v-model="userInfo.phone" :disabled="disabled" type="text" maxlength="11"
                @input="(v) => (userInfo.phone = v.replace(/[^\d]/g, ''))"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" label-width="100px" prop="email">
              <el-input v-model="userInfo.email" :disabled="disabled"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="地址" label-width="100px" prop="adder">
              <el-input v-model="userInfo.adder" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="状态" label-width="100px" prop="status">
              <el-select v-model="userInfo.status" placeholder="请选择" :disabled="disabled">
                <el-option label="正常" value="0"></el-option>
                <el-option label="禁用" value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="职位" label-width="100px" prop="roles">
              <el-select v-model="userInfo.roles" multiple filterable allow-create default-first-option
                placeholder="请选择职位" :disabled="disabled">
                <el-option v-for="item in sysRoles" :key="item.id" :label="item.roleName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="float: right;" v-show="!disabled">
            <el-button size="mini" @click="dialogUserVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="update">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sysRoles: null,
      total: 0,
      page: {
        query: {
          userName: '',
          nickName: '',
          phone: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      dialogAdduserVisible: false,
      addform: {
        nickName: '',
        phone: '',
        email: '',
        adder: '',
        roles: []
      },
      dialogUserVisible: false,
      disabled: null,
      userInfo: {},
      visible: false,
      rules: {
        nickName: [{ required: true, message: '请输入用户名', trigger: ['blur'] }],
        phone: [{ required: true, message: '请输入手机号', trigger: ['blur'] }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur'] }],
        email: [{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的电子邮箱', trigger: ['blur'] }],
        adder: [{ required: true, message: '地址不能为空', trigger: ['blur'] }],
        userType: [{ required: true, message: '职位不能为空', trigger: ['blur'] }]
      },
      updateRules: {
        id: [{ required: true, message: 'id不能修改', trigger: ['blur'] }],
        userName: [{ required: true, message: '账号不能修改', trigger: ['blur'] }],
        nickName: [{ required: true, message: '请输入用户名', trigger: ['blur'] }],
        phone: [{ required: true, message: '请输入手机号', trigger: ['blur'] }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur'] }],
        email: [{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的电子邮箱', trigger: ['blur'] }],
        adder: [{ required: true, message: '地址不能为空', trigger: ['blur'] }],
        status: [{ required: true, message: '状态不能为空', trigger: ['blur'] }],
        roles: [{ required: true, message: '职位不能为空', trigger: ['blur'] }]
      }

    }
  },
  created() {
    this.getLsit()
    this.getRoleList()
  },
  methods: {
    // 权限查询
    async getRoleList() {
      const { data: res } = await this.$http.get('/admin/sys/role/getRoleList')
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.sysRoles = res.data
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/sys/user/sysUserList', this.page)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.userList
    },
    // 重置查询条件
    reset() {
      this.page.query.userName = ''
      this.page.query.nickName = ''
      this.page.query.phone = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    },
    // 列表权限处理
    showRoles(roles) {
      let rolesValue = ''
      for (let i = 0; i < roles.length; i++) {
        for (let y = 0; y < this.sysRoles.length; y++) {
          if (roles[i] === this.sysRoles[y].id) {
            rolesValue = rolesValue + this.sysRoles[y].roleName + '、'
          }
        }
      }
      if (rolesValue.length !== 0) {
        rolesValue = rolesValue.substring(0, rolesValue.length - 1)
      }
      return rolesValue
    },
    // 新增用户信息
    async addUser() {
      this.$refs.addform.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/admin/sys/user/add', this.addform)
        console.log(res)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.addform = {}
        this.dialogAdduserVisible = false
        this.getLsit()
      })
    },
    onClick(row, b) {
      this.userInfo = row
      this.dialogUserVisible = true
      this.disabled = b
      console.log(this.userInfo.id)
    },
    async updateUser(user) {
      const { data: res } = await this.$http.post('/admin/sys/user/update', user)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      // this.$message.success(res.msg)
      this.dialogUserVisible = false
      this.getLsit()
    },
    // 删除操作
    async onDel(row) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const user = {
          id: row.id,
          isDel: 1
        }
        this.updateUser(user)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    update() {
      this.$refs.updateRules.validate(async valid => {
        if (!valid) return
        this.updateUser(this.userInfo)
        this.$message.success('更新成功！')
      })
    }

  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 25%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.el-dialog {
  >div {
    .el-form {
      >div {
        display: flex;
        flex-direction: row;

        .el-button {
          margin: -10px 5px;
        }

      }
    }
  }

}
</style>
