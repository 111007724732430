<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">应用管理</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">商品管理</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>商品ID</span>
              <el-input placeholder="请输入商品ID" v-model="page.query.goodsId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>商品名称</span>
              <el-input placeholder="请输入商品名称" v-model="page.query.goodsName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="primary" size="mini" @click="onClick({}, 1)">新增</el-button>
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="goodsId" align="center" label="商品ID" width="150">
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" width="180">
        </el-table-column>
        <el-table-column prop="goodsDesc" align="center" label="商品描述" width="250">
        </el-table-column>
        <el-table-column prop="stock" align="center" label="库存" width="100">
        </el-table-column>
        <el-table-column prop="goodsImage" align="center" label="商品图片" width="150">
          <template slot-scope="scope">
            <img :src="scope.row.goodsImage" alt="" style="width: 100px; height: 100px; border-radius:5px">
          </template>
        </el-table-column>
        <el-table-column prop="points" align="center" label="兑换积分" width="100">
        </el-table-column>
        <el-table-column prop="goodsClass" align="center" label="商品分类" width="100">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row, 3)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row, 2)" circle icon="el-icon-edit" type="primary"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click="onDel(scope.row)" circle icon="el-icon-delete" type="danger"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :title="handleTitle()" width="40%" center :visible.sync="dialoGoodsVisible">
      <div>
        <el-form :model="goods" :rules="goodsRules" ref="goodsRef">
          <div>
            <el-form-item label="id" label-width="100px" prop="goodsId" v-if="false">
              <el-input v-model="goods.goodsId" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="商品名称" label-width="100px" prop="goodsName">
              <el-input v-model="goods.goodsName" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="商品描述" label-width="100px" prop="goodsDesc">
              <el-input v-model="goods.goodsDesc" placeholder="请输入商品描述" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="商品图片" label-width="100px">
              <el-upload class="upload-demo" :action="this.$http.defaults.baseURL + '/admin/common/update/image'"
                :multiple="false" :limit="1" :before-upload="beforeUpload" :on-success="handleAvatarSuccess"
                :headers="headers">
                <el-image :src="goods.goodsImage" style="width: 100px; height: 100px; border-radius: 5px;"
                  v-if="goods.goodsImage"></el-image>
                <el-image :src="require('../../../public/上传图片.png')"
                  style="width: 100px; height: 100px; border-radius: 5px;" v-else></el-image>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="图片URL" label-width="100px" prop="goodsImage">
              <el-input v-model="goods.goodsImage" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="兑换积分" label-width="100px" prop="points">
              <el-input v-model="goods.points" placeholder="请输入兑换所需的积分"
                @input="(v) => (goods.points = v.replace(/[^\d.]/g, ''))" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="商品分类" label-width="100px" prop="goodsClass">
              <el-input v-model="goods.goodsClass" placeholder="请输入商品分类" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="库存" label-width="100px" prop="stock">
              <el-input v-model="goods.stock" placeholder="请输入库存" :disabled="tancFlag == '3'"></el-input>
            </el-form-item>
          </div>
          <div style=" display: flex; justify-content: flex-end;" v-show="tancFlag != '3'">
            <el-button size="mini" @click="dialoGoodsVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submit">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          goodsId: '',
          goodsName: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      goods: {},
      dialoGoodsVisible: false,
      goodsRules: {
        goodsName: [{ required: true, message: '请输入商品名称', trigger: ['blur'] }],
        goodsDesc: [{ required: true, message: '请输入商品描述', trigger: ['blur'] }],
        goodsImage: [{ required: true, message: '请上传商品图片', trigger: ['blur'] }],
        points: [{ required: true, message: '请输入兑换积分', trigger: ['blur'] }],
        stock: [{ required: true, message: '请输入库存数量', trigger: ['blur'] }]
      },
      tancFlag: '1'
    }
  },
  created() {
    this.getLsit()
  },
  methods: {
    async onDel(row) {
      row.isDel = '1'
      const { data: res } = await this.$http.post('/admin/goods/updateGoods', row)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getLsit()
    },

    submit() {
      this.$refs.goodsRef.validate(async valid => {
        if (!valid) return
        if (this.tancFlag == '1') {
          const { data: res } = await this.$http.post('/admin/goods/saveGoods', this.goods)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        if (this.tancFlag == '2') {
          const { data: res } = await this.$http.post('/admin/goods/updateGoods', this.goods)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        this.dialoGoodsVisible = false
        this.getLsit()
      })
    },
    onClick(row, flag) {
      this.tancFlag = flag
      this.goods = row
      this.dialoGoodsVisible = true
    },
    handleTitle() {
      if (this.tancFlag == '1') {
        return '新增商品'
      }
      if (this.tancFlag == '2') {
        return '修改商品'
      }
      if (this.tancFlag == '3') {
        return '查看商品详情'
      }
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/goods/getPageByGoodsList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.goodsId = ''
      this.page.query.goodsName = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    },
    handleAvatarSuccess(e) {
      if (e.code !== 200) return this.$message.error('图片上传失败！')
      this.goods.goodsImage = e.data
      this.$forceUpdate()
      // console.log('文件地址：' + e.data)
    },
    // 限制文件类型
    beforeUpload(file) {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$message.error('请上传png,jpeg格式的文件!')
        return false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
