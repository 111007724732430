import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import { MessageBox } from 'element-ui'

import axios from 'axios'
// axios.defaults.baseURL = 'http://118.89.174.90:9090'
// axios.defaults.baseURL = 'http://192.168.0.106:9090'
axios.defaults.baseURL = 'https:///www.xianlao.site'
// 请求拦截器
axios.interceptors.request.use(config => {
  // 把token放入请求头
  config.headers.Os = 'pc-1'
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.data.code === 500 && response.data.msg === '认证过期，请重新登录') {
    // 清理过期token
    window.sessionStorage.setItem('token', '')
    setTimeout(() => {
      router.push('/login')
    }, 1500)
    return Promise.reject(response.data.msg)
  }
  return response
}, function (err) {
  if (err.response.data.code === 500 && err.response.data.msg === '认证过期，请重新登录') {
    window.sessionStorage.setItem('token', '')
    setTimeout(() => {
      router.push('/login')
    }, 1500)
    return Promise.reject(err.response.data.msg)
  }
  return err
})
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
