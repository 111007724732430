import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import RootIndex from '@/components/RootIndex.vue'
import Myuser from '@/components/user/Myuser.vue'
import Consumeuser from '@/components/user/Consumeuser.vue'
import Auditing from '@/components/audit/Auditing.vue'
import Processing from '@/components/audit/Processing.vue'
import ExchangeHistory from '@/components/audit/ExchangeHistory.vue'
import AuditError from '@/components/audit/AuditError.vue'
import GameList from '@/components/game/GameList.vue'
import GameConfig from '@/components/game/GameConfig.vue'
import GoodsList from '@/components/goods/GoodsList.vue'
import OrderList from '@/components/order/OrderList.vue'
import JobList from '@/components/sys/JobList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/rootIndex',
    children: [
      { path: '/rootIndex', component: RootIndex },
      { path: '/myuser', component: Myuser },
      { path: '/consumeuser', component: Consumeuser },
      { path: '/audit/auditing', component: Auditing },
      { path: '/audit/processing', component: Processing },
      { path: '/audit/exchangeHistory', component: ExchangeHistory },
      { path: '/audit/auditError', component: AuditError },
      { path: '/game/gameList', component: GameList },
      { path: '/game/gameConfig', component: GameConfig },
      { path: '/goods/goodsList', component: GoodsList },
      { path: '/order/orderList', component: OrderList },
      { path: '/sys/jobList', component: JobList }

    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
