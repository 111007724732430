<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">数据统计</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">充值统计</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>用户ID</span>
              <el-input placeholder="请输入用户ID" v-model="page.query.userId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>状态</span>
              <el-select v-model="page.query.status" placeholder="请选择" :disabled="tancFlag != '1'">
                <el-option v-for="item in orderStatusTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>起止时间</span>
              <el-date-picker v-model="page.query.date" type="daterange" align="right" unlink-panels range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="orderId" align="center" label="订单编号" width="240">
        </el-table-column>
        <el-table-column prop="userId" align="center" label="用户ID" width="100">
        </el-table-column>
        <el-table-column prop="amount" align="center" label="充值金额" width="100">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="100">
          <template slot-scope="scope">
            <span>{{ showStatus(scope.row.status, orderStatusTypes) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sourceType" align="center" label="订单来源" width="100">
        </el-table-column>
        <el-table-column prop="createUser" align="center" label="创建人" width="100">
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="updateUser" align="center" label="修改人" width="100">
        </el-table-column>
        <el-table-column prop="updateTime" align="center" label="最后修改时间" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="退款" placement="top">
              <el-button @click="onRefund(scope.row)" circle icon="el-icon-refresh-left" type="danger"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog title="查看订单详情" width="40%" center :visible.sync="dialoOrderVisible">
      <div>
        <el-form :model="order" :rules="orderRules" ref="orderRef">
          <div>
            <el-form-item label="订单编号" label-width="100px" prop="orderId">
              <el-input v-model="order.orderId" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="用户ID" label-width="100px" prop="userId">
              <el-input v-model="order.userId" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="订单金额" label-width="100px" prop="amount">
              <el-input v-model="order.amount" disabled></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="订单状态" label-width="100px" prop="status">
              <el-select v-model="order.status" placeholder="请选择" disabled>
                <el-option v-for="item in orderStatusTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="订单来源" label-width="100px" prop="sourceType">
              <el-input v-model="order.sourceType" disabled></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          userId: '',
          status: '',
          date: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      order: {},
      orderStatusTypes: [],
      dialoOrderVisible: false
    }
  },
  created() {
    this.getOrderStatusType()
    this.getLsit()
    this.getGameList()
  },
  methods: {
    onClick(row) {
      this.order = row
      this.dialoOrderVisible = true
    },
    showStatus(val, dictArr) {
      if (val !== '') {
        return dictArr.filter(item => item.value === val)[0].label
      }
    },
    getOrderStatusType() {
      const types = window.sessionStorage.getItem('order_status_type')
      this.orderStatusTypes = JSON.parse(types).map(item => {
        return { value: item.dictValue, label: item.dictLabel }
      })
    },

    async onRefund(row) {
      if (row.status != '4') {
        return this.$message.error('当前订单不支持退款！')
      }
      const { data: res } = await this.$http.post('/admin/order/refund', row)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getLsit()
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/order/getPageByOrderList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.userId = ''
      this.page.query.status = ''
      this.page.query.date = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
