<template>
  <el-container class="home-container">
    <!--头部-->
    <el-header>
      <div>
        <img src="./../../public/favicon.png" alt="" class="logo">
        <span>管理系统</span>
      </div>
      <div>
        <img src="./../../public/favicon.png" class="nav_avatar" @click="dialogFormVisible = true">
        <el-button type="mini" @click="logout">退出</el-button>
      </div>
    </el-header>
    <!--主体-->
    <el-container>
      <!--侧边栏-->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu background-color="rgb(76,74,88)" text-color="#e1e1e4" active-text-color="#049EFF" unique-opened
          :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <!--一级菜单-->
          <el-submenu :index="itme.id + ''" v-for="itme in menuList" :key="itme.id">
            <!--一级菜单模版区域-->
            <template slot="title">
              <!--图标-->
              <i :class="itme.style"></i>
              <!--文本-->
              <span>{{ itme.menuName }}</span>
            </template>
            <!--二级菜单-->
            <el-menu-item :index="subItme.url" v-for="subItme in itme.children" :key="subItme.id"
              @click="saveNavState(subItme.url)">
              <!--图标-->
              <i :class="subItme.style"></i>
              <!--文本-->
              <span>{{ subItme.menuName }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧内容-->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <!--弹窗内容-->
    <el-dialog title="个人信息" width="50%" center :visible.sync="dialogFormVisible">
      <div style="display: flex;flex-direction: row;">
        <div><img src="./../../public/favicon.png"
            style="height: 150px;width: 150px; border: 2px dashed rgb(160, 159, 159);border-radius: 5%;"></div>
        <el-form :model="form">
          <div>
            <el-form-item label="账号" label-width="100px">
              <el-input v-model="form.userName" disabled></el-input>
            </el-form-item>
            <el-form-item label="状态" label-width="100px">
              <el-select v-model="form.status" placeholder="请选择" disabled>
                <el-option label="正常" value="0"></el-option>
                <el-option label="封禁" value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="昵称" label-width="100px">
              <el-input v-model="form.nickName"></el-input>
            </el-form-item>
            <el-form-item label="电话" label-width="100px">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="邮箱" label-width="100px">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="密码" label-width="100px">
              <el-link type="primary" @click="innerVisible = true">修改</el-link>
            </el-form-item>
          </div>
          <div style="float: right;">
            <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="update">确 定</el-button>
          </div>
        </el-form>
      </div>
      <!--预留气泡位置-->
      <!-- <el-divider></el-divider> -->

      <el-dialog width="40%" title="修改密码" center :visible.sync="innerVisible" append-to-body>
        <el-form :model="passform">
          <el-form-item label="当前密码" label-width="100px">
            <el-input v-model="passform.oldpass" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码" label-width="100px">
            <el-input v-model="passform.newpass" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" label-width="100px">
            <el-input v-model="passform.conpass" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="innerVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="updapass">确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: window.sessionStorage.getItem('menuList'),
      isCollapse: false,
      activePath: '',
      dialogFormVisible: false,
      innerVisible: false,
      form: {
        userId: '',
        status: '',
        userName: '',
        phone: '',
        email: ''
      },
      passform: {
        oldpass: '',
        newpass: '',
        conpass: ''
      }
    }
  },
  created() {
    this.getMenuList()
    this.queryUser()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.cacheData()
  },

  methods: {
    // 缓存字典项
    async cacheData() {
      const { data: res } = await this.$http.get('/admin/sysDict/getDictAll')
      if (res.code !== 200) return this.$message.error(res.msg)
      const e = new Map(Object.entries(res.data))
      for (const [key] of e) {
        window.sessionStorage.setItem(key, JSON.stringify(e.get(key)))
      }
    },
    async logout() {
      await this.$http.get('/logout')
      // console.info('退出===' + res.code)
      window.sessionStorage.clear()
      this.$message.success('退出成功！')
      this.$router.push('/login')
    },
    // 获取菜单列表
    async getMenuList() {
      const { data: res } = await this.$http.get('/admin/sys/menu/list')
      // console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      window.sessionStorage.setItem('menuList', res.treeMenu)
      this.menuList = res.treeMenu
    },
    // 菜单折叠和展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    avatar() {
      // console.info('头像')
    },
    // 修改密码
    async updapass() {
      // console.info('修改密码')
      const { data: res } = await this.$http.post('/admin/sys/user/updatePass', this.passform)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.innerVisible = false
    },
    // 修改基本信息
    async update() {
      const { data: res } = await this.$http.post('/admin/sys/user/update', this.form)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.dialogFormVisible = false
    },
    // 查询用户基本信息
    async queryUser() {
      const { data: res } = await this.$http.get('/admin/sys/user/queryUser')
      this.form = res.user
    }
  }
}
</script>
<style lang="less" scoped>
.home-container {
  width: 100%;
  height: 100%;
}

.el-header {
  background-color: rgb(76, 74, 88);
  display: flex;
  justify-content: space-between;
  // padding-left: 0;
  align-items: center;
  color: #ffffff;
  font-size: 20px;

  >div {
    display: flex;
    align-items: center;

    .logo {
      height: 75px;
      width: 75px;
    }

    .nav_avatar {
      height: 50px;
      width: 50px;
      margin-right: 20px;
      background-color: #e1e1e4;
      border-radius: 50%;
    }

    span {
      margin-left: 15px;
    }
  }
}

.el-form {
  >div {
    display: flex;
    flex-direction: row;

  }
}

.el-aside {
  background-color: rgb(76, 74, 88);

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: rgb(60, 59, 70);
}

.iconfont {
  margin-left: 10px;
}

.toggle-button {
  background-color: rgb(76, 74, 88);
  ;
  font-size: 10px;
  line-height: 24px;
  color: #e1e1e4;
  text-align: center;
  letter-spacing: 0.2em;
}
</style>
