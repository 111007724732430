<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">兑换审核</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">待审核</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>用户ID</span>
              <el-input placeholder="请输入用户id" v-model="page.query.userId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="9">
            <div>
              <span>商品名称</span>
              <el-input placeholder="请输入商品名称" v-model="page.query.goodsName">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="warning" size="mini">导入</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="ID" width="200" v-if="false">
        </el-table-column>
        <el-table-column prop="userId" align="center" label="用户ID" width="100">
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" width="150">
        </el-table-column>
        <el-table-column prop="goodsNum" align="center" label="兑换数量" width="100">
        </el-table-column>
        <el-table-column prop="depletePoints" align="center" label="消费积分" width="150">
        </el-table-column>
        <el-table-column prop="rechargeNo" align="center" label="充值账号" width="200">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="180">
          <template slot-scope="scope">
            <span>{{ showStatus(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" width="200">
        </el-table-column>
        <el-table-column prop="updateTime" align="center" label="最后处理时间" width="200">
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          userId: '',
          goodsName: '',
          status: '5'
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: []
    }
  },
  created() {
    this.getLsit()
  },
  methods: {
    showStatus(val) {
      const types = window.sessionStorage.getItem('exchange_status_type')
      const s = JSON.parse(types).find(x => x.dictValue === val)
      return s.dictLabel
    },
    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/exchange/getPageByExchangeList', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.userId = ''
      this.page.query.goodsName = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
