<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/rootIndex' }"><span
          style="font-size:14px;color:#ffffff;font-weight=600;">首页</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#e6a23c;">系统管理</span></el-breadcrumb-item>
      <el-breadcrumb-item><span style="font-size:12px;color:#66b1ff;">任务管理</span></el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片-->
    <el-card shadow=" hover">
      <el-row>
        <el-form :model="page.query">
          <el-col :span="6">
            <div>
              <span>任务ID</span>
              <el-input placeholder="请输入任务ID" v-model="page.query.userId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>任务名称</span>
              <el-input placeholder="请输入任务名称" v-model="page.query.userId">
              </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="success" round @click="getLsit">
              查询
            </el-button>
            <el-button type="info" round @click="reset">
              清空
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <el-button type="primary" size="mini" @click="onClick({}, 1)">新增</el-button>
      <el-button type="danger" size="mini">导出</el-button>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" align="center" label="任务ID" width="160">
        </el-table-column>
        <el-table-column prop="jobName" align="center" label="任务名称" width="240">
        </el-table-column>
        <el-table-column prop="cronExpr" align="center" label="cron表达式" width="180">
        </el-table-column>
        <el-table-column prop="jobStatus" align="center" label="任务状态" width="180">
          <template slot-scope="scope">
            <span>{{ showStatus(scope.row.jobStatus, jobStatusTypes) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="url" align="center" label="业务接口" width="200">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button @click="onClick(scope.row, 3)" circle icon="el-icon-search" type="success"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="onClick(scope.row, 2)" circle icon="el-icon-edit" type="success"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页内容-->
      <div class="nva_pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.pageNum"
          :page-sizes="[5, 10, 20, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :title="handleTitle()" width="40%" center :visible.sync="dialoJobVisible">
      <div>
        <el-form :model="job" :rules="jobRules" ref="jobRef">
          <div>
            <el-form-item label="任务ID" label-width="100px" prop="id" v-if="tancFlag != '1'">
              <el-input v-model="job.id" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="任务名称" label-width="100px" prop="jobName">
              <el-input v-model="job.jobName" :disabled="tancFlag == '3'"> </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label=" cron表达式" label-width="100px" prop="cronExpr">
              <el-input v-model="job.cronExpr" placeholder="请输入cron表达式" :disabled="tancFlag == '3'">
              </el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label=" 任务状态" label-width="100px" prop="status">
              <el-select v-model="job.jobStatus" placeholder="请选择" :disabled="tancFlag == '3'">
                <el-option v-for=" item in jobStatusTypes" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="业务接口" label-width="100px" prop="sourceType">
              <el-input v-model="job.url" :disabled="tancFlag == '3'"> </el-input>
            </el-form-item>
          </div>
          <div style=" display: flex; justify-content: flex-end;" v-show="tancFlag != '3'">
            <el-button size="mini" @click="dialoJobVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="submit">确 定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      page: {
        query: {
          id: '',
          jobName: '',
          jobStatus: ''
        },
        pageNum: 1,
        pageSize: 5
      },
      tableData: [],
      job: {},
      jobStatusTypes: [],
      dialoJobVisible: false,
      tancFlag: 1,
      jobRules: {
        jobName: [{ required: true, message: '请输入任务名称', trigger: ['blur'] }],
        cronExpr: [{ required: true, message: '请输入cron表达式', trigger: ['blur'] }]
      }
    }
  },
  created() {
    this.getJobStatusTypes()
    this.getLsit()
  },
  methods: {
    async submit() {
      this.$refs.jobRef.validate(async valid => {
        if (!valid) return
        if (this.tancFlag == '1') {
          const { data: res } = await this.$http.post('/admin/job/createJob', this.job)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        if (this.tancFlag == '2') {
          const { data: res } = await this.$http.post('/admin/job/updateJob', this.job)
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
        }
        this.dialoJobVisible = false
        this.getLsit()
      })
    },
    onClick(row, flag) {
      this.tancFlag = flag
      this.job = row
      this.dialoJobVisible = true
    },

    handleTitle() {
      if (this.tancFlag == '1') {
        return '新增任务'
      }
      if (this.tancFlag == '2') {
        return '修改任务'
      }
      if (this.tancFlag == '3') {
        return '查看任务详情'
      }
    },
    showStatus(val, dictArr) {
      if (val !== '') {
        return dictArr.filter(item => item.value === val)[0].label
      }
    },
    getJobStatusTypes() {
      const types = window.sessionStorage.getItem('job_status_type')
      this.jobStatusTypes = JSON.parse(types).map(item => {
        return { value: item.dictValue, label: item.dictLabel }
      })
    },

    // 列表查询
    async getLsit() {
      const { data: res } = await this.$http.post('/admin/job/getPageByJob', this.page)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.tableData = res.data.records
    },
    // 重置查询条件
    reset() {
      this.page.query.id = ''
      this.page.query.jobName = ''
      this.page.query.jobStatus = ''
    },
    // 分页 改变每页条数
    handleSizeChange(pageSize) {
      this.page.pageNum = 1
      this.page.pageSize = pageSize
      this.getLsit()
    },
    // 分页 改变页数
    handleCurrentChange(pageNum) {
      this.page.pageNum = pageNum
      this.getLsit()
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  display: flex;
  flex-direction: row;
  justify-content: center;

  >div {
    display: flex;
    flex-direction: row;

    >span {
      display: flex;
      justify-content: center;
      /* 相对父元素水平居中 */
      align-items: center;
      width: 40%;
    }
  }
}

.el-card {
  margin-top: 10px;
}

.nva_pagination {
  margin: 10px 5px;
  display: flex;
  flex-direction: row-reverse;
}

.update-but {

  display: flex;
  justify-content: flex-end;
}
</style>
