<template>
  <div class="box">
    <div class="box_1">
      <div class="box_header">
        <div class="box_header_title">
          <span>待审核数据： {{ list1Total }} 条 </span>
        </div>
        <div class="box_header_icon" @click="goToAuditing()">
          <i class="el-icon-right"></i>
        </div>
      </div>
      <div class="box_mian">
        <el-table :data="list1" style="width: 100%;">
          <el-table-column type="index" align="center" width="70"></el-table-column>
          <el-table-column prop="userId" align="center" label="用户ID" width="100">
          </el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称" width="180">
          </el-table-column>
          <el-table-column prop="goodsNum" align="center" label="兑换数量" width="100">
          </el-table-column>
          <el-table-column prop="depletePoints" align="center" label="消费积分" width="100">
          </el-table-column>
        </el-table>

      </div>

    </div>
    <div class="box_2">
      <div class="box_header">
        <div class="box_header_title">
          <span>待兑换数据： {{ list2Total }} 条 </span>
        </div>
        <div class="box_header_icon" @click="goToProcessing()">
          <i class=" el-icon-right"></i>
        </div>
      </div>
      <el-table :data="list2" style="width: 100%;">
        <el-table-column type="index" align="center" width="70"></el-table-column>
        <el-table-column prop="userId" align="center" label="用户ID" width="100">
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" width="200">
        </el-table-column>
        <el-table-column prop="goodsNum" align="center" label="兑换数量" width="100">
        </el-table-column>
        <el-table-column prop="depletePoints" align="center" label="消费积分" width="100">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list1Total: 0,
      list1: [],
      list2Total: 0,
      list2: []
    }
  },
  created() {
    this.getExchangeList1()
    this.getExchangeList2()
  },
  methods: {
    goToAuditing() {
      this.$router.push({ path: '/audit/auditing' })
    },
    goToProcessing() {
      this.$router.push({ path: '/audit/processing' })
    },

    // 列表查询
    async getExchangeList1() {
      const obj = {
        query: {
          status: '1'
        },
        pageNum: 1,
        pageSize: 5
      }
      const { data: res } = await this.$http.post('/admin/exchange/getPageByExchangeList', obj)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.list1Total = res.data.total
      this.list1 = res.data.records
    },

    // 列表查询
    async getExchangeList2() {
      const obj = {
        query: {
          status: '4',
          isUser: '1'
        },
        pageNum: 1,
        pageSize: 5
      }
      const { data: res } = await this.$http.post('/admin/exchange/getPageByExchangeList', obj)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.list2Total = res.data.total
      this.list2 = res.data.records
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  justify-content: space-around;

  .box_1 {
    width: 45%;
    border-radius: 10px;
    background-color: #cfcccc;
    display: flex;
    flex-direction: column;

    .box_header {
      width: 100%;
      height: 40px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .box_header_title {
        margin-left: 10px;

        span {
          font-size: 20px;
          font-weight: 600;
          color: #9093a6;
        }
      }

      .box_header_icon {
        margin-right: 20px;

        i {
          font-size: 20px;
          font-weight: 600;
          color: #8888ff;
        }
      }
    }

    .box_mian {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .box_2 {
    width: 48%;
    border-radius: 10px;
    background-color: #cfcccc;
    display: flex;
    flex-direction: column;

    .box_header {
      width: 100%;
      height: 40px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .box_header_title {
        margin-left: 10px;

        span {
          font-size: 20px;
          font-weight: 600;
          color: #9093a6;
        }
      }

      .box_header_icon {
        margin-right: 20px;

        i {
          font-size: 20px;
          font-weight: 600;
          color: #8888ff;
        }
      }
    }
  }
}
</style>
