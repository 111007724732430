<template>
  <div class='login_container'>
    <div class='login_box'>
      <div class='avatar_box'>
        <img src='../../public/favicon.png'
          style='width: 130px; width: 130px;border-radius: 50%; background-color: #a2f0cc;' alt=''>
      </div>

      <el-form label-width='0px' ref='loginFormRef' :model='loginForm' :rules='loginFormRules' class='login_form'>
        <el-form-item prop='username'>
          <el-input v-model='loginForm.username' prefix-icon='iconfont icon-person_24'></el-input>
        </el-form-item>
        <el-form-item prop='password'>
          <el-input v-model='loginForm.password' type='password' prefix-icon='iconfont icon-unlocked_24'></el-input>
        </el-form-item>
        <!-- <div class='p_box'>
          <p>还没有账号？<a href=''>去注册</a></p>
          <p><a href=''>忘记密码?</a></p>
        </div> -->
        <el-form-item class='btns'>
          <el-button type='primary' @click='login'>登录</el-button>
          <el-button type='warning' @click='resetLoginForm'>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/login', this.loginForm
          ,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        if (res.code !== 200) return this.$message.error(res.msg)
        // console.info(res)
        this.$message.success('登录成功！')
        window.sessionStorage.setItem('token', res.token)
        this.$router.push('/home')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {

  background: url("./../../public/login.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;

  .login_box {
    width: 30%;
    height: 350px;
    border-radius: 2%;
    background-color: #ffffff;
    opacity: 0.7;
    position: absolute;
    left: 55%;
    top: 35%;

    .avatar_box {
      width: 130px;
      height: 130px;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #dddddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
    }

  }
}

.login_form {
  position: absolute;
  bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;

  .p_box {
    font-family: "宋体";
    font-size: 12px;
    display: flex;
    justify-content: space-between
  }
}

.btns {
  margin-left: 60%;
}
</style>
